import React from "react";

export const Cross = ({ color = "#fff" }: any) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="12.7275"
      width="2"
      height="18"
      transform="rotate(45 12.7275 0)"
      fill={color}
    />
    <rect
      y="1.41431"
      width="2"
      height="18"
      transform="rotate(-45 0 1.41431)"
      fill={color}
    />
  </svg>
);
