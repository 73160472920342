/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import { Button, Modal, Row } from "antd";
import "../styles/fonts/bianco/fonts.css";
import "../styles/fonts/notera/fonts.css";
import "../styles/fonts/lato/fonts.css";
import "./Layout.css";
import { UserSignupHeader } from "./Headers/UserSignupHeader";
import { getCookie } from "../utils/getCookie";
import { setCookie } from "../utils/setCookie";

type LayoutProps = {
  children: React.ReactNode,
  header?: any,
  footer?: any
}

const Layout = ({ children, header, footer }: LayoutProps) => {
  const [cookiesNoticeModal, setCookiesNoticeModal] = useState(false);
  const [paddingBtm, setPaddingBtm] = useState(100);
  const [, forceUpdate] = useState(0);
  useEffect(() => {
    const setPad = () => {
      const ftrs = document.getElementsByClassName("footer_bar");
      if (ftrs.length > 0) {
        setPaddingBtm(ftrs[0].clientHeight);
      }
    };
    document.addEventListener("resize", setPad);
    setPad();
    return () => {
      document.removeEventListener("resize", setPad);
    };
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        position: "relative",
        paddingBottom: paddingBtm,
      }}
    >
      {header || <UserSignupHeader closeBtn={false} />}
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>{children}</main>
      </div>
      {footer}
      {!getCookie("cookiesAccepted") ? (
        <Row
          style={{
            position: "fixed",
            bottom: 0,
            background: "rgb(51, 62, 71)",
            width: "100%",
            padding: 20,
            color: "white",
          }}
          align="middle"
          justify="center"
        >
          <div>
            We use cookies to ensure that we give you the best experience on our
            website. If you continue to use this site we will assume that you
            are happy with it.
          </div>
          <Button
            type="primary"
            onClick={() => {
              setCookie("cookiesAccepted", true);
              forceUpdate(o => o + 1);
            }}
            style={{ marginLeft: 20, marginRight: 20 }}
          >
            I Agree
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setCookiesNoticeModal(true);
            }}
          >
            Privacy Policy
          </Button>
          <Modal
            visible={cookiesNoticeModal}
            onOk={() => {
              setCookiesNoticeModal(false);
            }}
            onCancel={() => {
              setCookiesNoticeModal(false);
            }}
          >
            GLOBAL PRECIOUS METALS PTE. LTD. PRIVACY NOTICE INTRODUCTION Global
            Precious Metals Pte. Ltd. (“GPM”) is committed to protecting and
            respecting your privacy by complying with the EU General Data
            Protection Regulation 2016/679 (“GDPR”), when applicable, the
            Personal Data Protection Act (“PDPA”) and any other locally
            applicable data protection laws. This data protection privacy notice
            (“Privacy Notice”) lets you know what happens to any personal data
            that you give to us, or any that we may collect from or about you.
            Subject to section 3, it applies to all services and instances where
            we collect your personal data. This Privacy Notice does not apply to
            the collection of personal data through our website or through
            cookies with respect to which personal data GPM can be considered a
            data controller. In this regard please see our separate privacy
            statement and cookies policy at
            https://global-precious-metals.com/privacy-statment/. GPM (in this
            Privacy Notice, “us”, “we” and “our”) is the data controller of your
            personal data, this is, we determine the purposes and means of the
            processing of that personal data. This Privacy Notice is available
            through GPM’s website
            https://global-precious-metals.com/privacy-statment/. GPM reserves
            the right to update this Privacy Notice without consulting or
            pre-informing you. PERSONAL DATA We may collect the following
            categories of personal data about you: your full name and contact
            information such as your home or business address, email address and
            telephone number; biographical information which may confirm your
            identity including your date of birth, gender, tax identification
            number, passport number or national identity card details,
            nationality and/or country of residence; information in relation to
            your financial situation such as income, expenditure, assets and
            liabilities, sources of wealth, as well as bank account details;
            information about your employment, education, family or personal
            circumstances, and interests, where relevant; information to assess
            whether you may represent a politically exposed person or money
            laundering COLLECTION OF PERSONAL DATA We will collect your personal
            data from different sources, for example: from you directly, and any
            information from family members, if/where relevant; from your
            agents, advisers, intermediaries, custodians of your assets, or
            others who are a part of providing your services or operating our
            business; from publicly available sources or from third parties,
            most commonly where we need to conduct background checks about you.
            LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA We rely on the
            following legal basis to process your personal data: the processing
            is necessary to perform a contract with you to provide you with our
            services; the processing is necessary for us to comply with our
            legal obligations; the processing is necessary for our legitimate
            interests or for the legitimate interests of a third party. In this
            respect, we will make sure that we consider and balance any
            potential impact on you (both positive and negative) and your rights
            before we process your personal data for our legitimate interests;
            you have given clear consent to us to process your personal data for
            a specific purpose. PURPOSES FOR WHICH WE MAY PROCESS YOUR PERSONAL
            DATA The purposes for which we may process personal data are:
            on-boarding you as a client; and compliance with our internal
            compliance requirements, policies and procedures; maintaining and
            updating your contact information where appropriate; marketing
            activities. Please see also section 7 below on Marketing; providing
            you with information or assistance that you request from us;
            conducting verifications, monitoring and reporting in accordance
            with anti-money laundering and counter terrorist financing laws;
            providing you with the services requested, including purchase and
            sales, custody, delivery of precious metals, amongst others;
            notifying you about changes to our services; monitoring and
            improving the quality of our services; training our staff; managing
            our business, including recovering money you owe to us, archiving or
            statistical analysis; seeking advice on our rights and obligations,
            such as where we require our own legal advice; complying with
            requests for information from tax authorities or any competent court
            or legal authority. DISCLOSURE OF PERSONAL DATA TO THIRD PARTIES
            Subject to binding contractual obligations of confidentiality where
            applicable, we may disclose your personal data to: your agents,
            advisers, intermediaries, and custodians of your assets who you tell
            us about; our professional advisers where it is necessary for us to
            obtain their advice or assistance, including lawyers, accountants,
            IT or public relations advisers; our data storage providers; private
            firms and/or government authorities when they are conducting audits
            for compliance, finance and/or accounting purposes; third parties
            whom we engage to assist in delivering the services to you; third
            parties and their advisers where those third parties are acquiring,
            or considering acquiring, all or part of our business; third parties
            who assist us in conducting background checks; governmental, legal,
            regulatory, or similar authorities, central and/or local government
            agencies, upon request or where required, including for the purposes
            of reporting any actual or suspected breach of applicable law or
            regulation. MARKETING We may send you marketing information about
            news, activities and services which we believe might be of interest
            to you. We will be sending the marketing information to you in a
            number of ways, including by post, email or other electronic means.
            If you do not want to receive our communications, you can withdraw
            your consent at any time just by sending an empty email to
            relationship@global-precious-metals.com. Please note that all
            processing of your personal data will cease once you have withdrawn
            consent but this will not affect any personal data that has already
            been processed prior to this point. INTERNATIONAL TRANSFERS OF
            PERSONAL DATA We are based in Singapore. Because of the
            international nature of our business, we may need to transfer your
            personal data to third parties as noted above in connection with the
            purposes set out in this Privacy Notice. For this reason, we may
            transfer your personal data to other countries that may have
            different laws and data protection compliance requirements. Where we
            transfer your personal data to other countries, we will make sure
            that suitable safeguards are in place, for example by using approved
            contractual agreements, unless certain exceptions apply. To find out
            more about international transfers by us of your personal data and
            the countries concerned please contact as at
            dpo@global-precious-metals.com. RETENTION OF YOUR PERSONAL DATA We
            will only retain your personal data for as long as necessary to meet
            any of the purposes for which it was collected or as required by
            law. DATA SECURITY We have implemented appropriate technical and
            organizational security measures designed to protect your personal
            data against accidental or unlawful destruction, loss, alteration,
            unauthorised disclosure, unauthorised access, and other unlawful or
            unauthorised forms of processing, in accordance with applicable law.
            You are responsible for ensuring that any personal data that you
            send to us are sent securely. YOUR LEGAL RIGHTS Subject to
            applicable law, you may have a number of rights regarding the
            processing of your personal data, including: The right to be
            informed about the processing of your personal information; The
            right to have your personal information corrected if it is
            inaccurate and to have incomplete personal information completed;
            The right to object to processing of your personal information; The
            right to restrict processing of your personal information; The right
            to have your personal information erased (the “right to be
            forgotten”); The right to request access to your personal
            information and to obtain information about how we process it; The
            right to move, copy or transfer your personal information (“data
            portability”); Rights in relation to automated decision making which
            has a legal effect or otherwise significantly affects you; The
            above-mentioned rights do not apply in all circumstances. To
            exercise one or more of these rights, or to ask a question about
            these rights please use the contact details provided in section 12
            CONTACT DETAILS If you have any comments, questions or concerns
            about any of the information in this Privacy Notice, or any other
            issues relating to the processing of your personal data please
            contact us: by post, to: Attention: Data Protection Officer Global
            Precious Metals Pte. Ltd. 143 Cecil Street, #07-01 GB Building,
            Singapore, 069542 by telephone, on +65 6224 9198; or by email, at
            dpo@global-precious-metals.com Last updated 13 January 2024
            ACKNOWLEDGMENT AND CONSENT TO THIS PRIVACY NOTICE By signing this
            Privacy Notice, you are confirming that you have read this Privacy
            Notice and that you are consenting to GPM holding and processing
            your personal data for the above-mentioned purposes as set out in
            Sections 5 of the Privacy Notice. You consent on your own behalf and
            duly authorised on behalf of any third party whose personal data you
            supply.
          </Modal>
        </Row>
      ) : null}{" "}
    </div>
  );
};

export default Layout;
