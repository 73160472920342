import { ENDPOINTS } from "./constants";

export const signup = (data: any = {}) =>
  fetch(ENDPOINTS.SIGNUP, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

export const updateUser = (userId, token, data: any = {}) =>
  fetch(`${ENDPOINTS.USERS}/${userId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

export const createDilligenceForm = diligenceType => (token, data = {}) =>
  fetch(`${ENDPOINTS.DILIGENCE_FORM}/${diligenceType}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

export const updateDilligenceForm = (dilligenceId, token, data = {}) =>
  fetch(`${ENDPOINTS.DILIGENCE_FORM}/${dilligenceId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

export const fetchUsers = token =>
  fetch(ENDPOINTS.LIST_USERS, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export const login = ({ username, password }) =>
  fetch(ENDPOINTS.LOGIN, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });

export const eraseUser = ({ userId, token }) =>
  fetch(`${ENDPOINTS.USERS}/${userId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export const fetchUser = ({ userId = undefined, token }) => {
  return fetch(userId ? `${ENDPOINTS.USERS}/${userId}` : ENDPOINTS.USERS, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
export const removeDocument = ({ docId, token }) =>
  fetch(`${ENDPOINTS.DOCUMENTS}/${docId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export const eraseDiligenceFormsByUser = ({ userId = undefined, token }) =>
  fetch(`${ENDPOINTS.DILIGENCE_FORM}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    ...(userId ? { body: JSON.stringify({ userId }) } : {}),
  });

export const verifPhoneCode = ({ token, code }) =>
  fetch(`${ENDPOINTS.VERIF_PHONE}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code }),
  });

export const setPassword = ({ token, verifCode, password }) =>
  fetch(`${ENDPOINTS.SET_PASSWORD}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ verifCode, password }),
  });

export const setEmailValidation = ({ token }) =>
  fetch(`${ENDPOINTS.SEND_VERIF_MAIL}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export const checkEmail = ({ token, ...body }) =>
  fetch(`${ENDPOINTS.CHECK_EMAIL}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

export const contact = ({ name, subject, email, msg }) =>
  fetch(`${ENDPOINTS.CONTACT}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, subject, email, msg }),
  });

export const sendBrochure = ({ email }) =>
  fetch(`${ENDPOINTS.BROCHURE}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
