import { navigate } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Affix, Row } from "antd";
import { Logo } from "../icons/Logo";
import { Cross } from "../icons/Cross";
import { useReset } from "../../utils/hooks";

export const UserSignupHeader = ({ closeBtn = true, style }) => {
  const reset = useReset();
  return (
    <div>
      <Affix offsetTop={0}>
        <header
          style={{
            background: `#000`,
            marginBottom: `40px`,
            height: "90px",
            display: "flex",
            alignItems: "center",
            ...style,
          }}
        >
          <div className="container signup_header">
            <Logo />
            {closeBtn && (
              <Row
                justify="space-between"
                align="middle"
                gutter={16}
                onClick={() => {
                  reset();
                  navigate("/");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    lineHeight: "26px",
                    color: "#fff",
                    fontSize: 14,
                    marginRight: 12,
                  }}
                >
                  Close
                </div>
                <Cross />
              </Row>
            )}
          </div>
        </header>
      </Affix>
    </div>
  );
};

UserSignupHeader.propTypes = {
  closeBtn: PropTypes.any,
  siteTitle: PropTypes.string,
  style: PropTypes.object,
};

UserSignupHeader.defaultProps = {
  siteTitle: ``,
};
