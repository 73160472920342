import { useEffect, useContext, useState, useCallback } from "react";
import { navigate } from "gatsby";
import { UserDispatchContext } from "../context/UserContextProvider";
import {
  AuthDispatchContext,
  AuthStateContext,
} from "../context/AuthContextProvider";
import { fetchUser, signup } from "./api";
import { DILIGENCE_FORMS, TITLES } from "./constants";
import { eraseAllCookies } from "./eraseAllCookies";
import { getCookie } from "./getCookie";
import { setCookie } from "./setCookie";

export function useSyncUser({
  getToken,
  onFail = null,
  failOnNoToken = false,
  createUser = false,
}: any) {
  const UserDispatch = useContext(UserDispatchContext);
  const AuthDispatch = useContext(AuthDispatchContext);
  const { accessToken } = useContext(AuthStateContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let persistedToken =
      typeof getToken === "function"
        ? getToken()
        : typeof getToken === "string"
        ? getToken
        : accessToken;
    if (!persistedToken) {
      persistedToken = getCookie("accessToken");
    }
    // console.log({ persistedToken });
    if (!persistedToken && failOnNoToken && onFail) onFail();
    else if (!persistedToken && createUser) {
      setIsLoading(true);
      signup({ title: TITLES.MR })
        .then(async r => {
          if (r.ok) {
            const {
              user: newUser,
              accessToken: newAccessToken,
            } = await r.json();

            setCookie("accessToken", newAccessToken);
            AuthDispatch({
              type: "set",
              payload: { accessToken: newAccessToken },
            });
            UserDispatch({ type: "set", payload: { user: newUser } });
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsLoading(false);
        });
    } else if (persistedToken && isLoading === false) {
      setIsLoading(true);
      fetchUser({ token: persistedToken })
        .then(async r => {
          if (r.status === 401) {
            if (onFail) onFail();
            return;
          }
          const d = await r.json();
          const { user: oldUser, documents, userDocuments, diligenceForm } = d;
          if (oldUser.role === "admin") {
            if (onFail) onFail();
            navigate("/");
            return;
          }
          AuthDispatch({
            type: "set",
            payload: { accessToken: persistedToken },
          });

          UserDispatch({
            type: "set",
            payload: {
              activeDiligenceFormTypeId:
                (diligenceForm && diligenceForm.diligenceForm) ||
                DILIGENCE_FORMS.INDIVIDUAL,
              user: oldUser,
              userDocuments,
              diligenceForm,
              documents,
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [UserDispatch, AuthDispatch, getToken]);

  return [isLoading];
}

export function useReset() {
  const UserDispatch = useContext(UserDispatchContext);
  const AuthDispatch = useContext(AuthDispatchContext);

  const reset = useCallback(() => {
    UserDispatch({
      type: "reset",
    });
    AuthDispatch({
      type: "reset",
    });
    eraseAllCookies();
  }, [UserDispatch, AuthDispatch]);

  return reset;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(
    typeof window !== "undefined" ? window.innerWidth : 900
  );

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
